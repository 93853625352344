.reel {
  display: grid;
  cursor: pointer;
}

.error-field {
  margin-top: 5px;
  text-align: left;
  color: #c43131;
  font-family: WorkSans;
}

.form-input {
  padding: 15px;
  border: none;
  font-family: WorkSans;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgb 0 0 0/6%;
  background-color: white;
  & :focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 12px;
  }
}

.chart-container {
  display: flex;
  justify-content: center;
  transform: scale(1);
  margin-bottom: 75px;

  @media (max-width: 768px) {
    transform: scale(0.88);
    margin-bottom: 0px;
  }
}

.textarea-box {
  margin-top: 10px;
  font-family: WorkSans;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.img-container {
  background-color: transparent;
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  z-index: 9;
  grid-area: 1/1;
  margin-left: 70px;
  margin-top: 72px;
  position: absolute;

  img {
    width: auto;
    height: 80%;
    object-fit: contain;
  }
}

.progress {
  grid-area: 1/1;
  height: 420px;
  width: 390px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;

  &.paused {
    .number {
      color: #929291;
    }

    .small {
      color: #929291;
    }

    .saved-text {
      color: #929291;
    }
  }

  .number {
    margin-left: 8px;
  }

  .small {
    font-size: 21px;
  }
}

.number {
  font-family: BlackHanSans;
  font-size: 58.7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  color: #000000;
}

.saved {
  grid-area: 1/1;
  height: 420px;
  width: 390px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  z-index: 2;

  .numer {
    font-size: 70px;
  }
}

.chart {
  grid-area: 1/1;
  height: 420px !important;
  width: 390px !important;
  position: absolute;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 1px, 0);
}

.saved-text {
  font-family: BlackHanSans;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.55;
  letter-spacing: 0.55px;
  position: relative;
}

.echarts-for-react {
  position: absolute;
  bottom: 0;
  bottom: 42px;
  width: 100%;
}

/* form */

.form-wrapper p {
  font-family: WorkSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.37px;
  color: #000000;
  padding-bottom: 10px;
}

.radio-button {
  margin-left: 7px;
  width: 100%;
}

.text-area-heading {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  color: #000000;
  font-family: BlackHanSans;
  margin-bottom: 5px;
}

.btn-submit {
  width: 100%;
  font-family: BlackHanSans;
  font-size: 12px;
}

.currency-input {
  padding: 20px;
  width: 100%;
  border: none;
  font-family: WorkSans;
  font-size: 20px;
  box-shadow: 0 2px 4px 0 rgb (0 0 0 / 6%);
  background-color: white;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 15px;
  }

  & :focus {
    outline: none;
  }
}

.phone-input {
  padding: 15px;
  border: none;
  font-family: WorkSans;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgb 0 0 0/6%;
  background-color: white;

  & :focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 12px;
  }
}

.input-container {
  width: 100%;
  padding-bottom: 10px;
}

.main-wrapper {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: self-start;
}

.increase-btn,
.decrease-btn {
  display: none;
}
.mobile-view-desc {
  display: none;
}

.desktaop-view-box {
  display: block;
  line-height: 1.43;
  letter-spacing: normal;
}

.product-name {
  font-family: VanguardCF;
  font-weight: bold;
  font-size: 1.5rem;
}
/*Success Popup*/
.chart-container.success-popup {
  display: flex;
  justify-content: center;
  transform: scale(0.7);
  margin-bottom: 0;
  margin-top: 35px;
  max-height: 300px !important;
  position: relative;
  min-width: auto !important;
  overflow: visible;

  & .reel {
    position: relative;
  }

  & .img-container {
    background-color: transparent;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    z-index: 9;
    grid-area: 1/1;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 0;
  }

  & .echarts-for-react {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  & .progress {
    grid-area: 1/1;
    height: 310px;
    width: 390px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 32px;
  }
}

.chip-info-wrapper {
  position: relative;
  padding: 42px;
  text-align: center;
  background-color: #ebf3f8;
  @media only screen and (max-width: 768px) {
    padding: 20px 20px;
  }
}

.chip-info-wrapper .heading {
  font-size: 3rem;
  font-family: VanguardCF;
  max-width: 85%;
  margin: 0;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.6px;
  color: black;
  text-align: left;
  width: 100%;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.2px;
  }
}

textarea {
  resize: none;
}

// .Modal {
//   transform: translate(-50%, 0%) !important;
//   top: 0px !important;
//   max-height: 96vh;
//   overflow: scroll;
// }

.owner-heading {
  font-size: 3rem;
  font-family: VanguardCF;
  max-width: 85%;
  margin: auto;
  padding: 25px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 24px;
    max-width: 80%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-device-width: 768px) {
  .main-wrapper {
  }

  .text-area-heading {
    text-align: left;
  }

  p.desktaop-view-box {
    margin-bottom: 50px;
  }
}

@media only screen and (max-device-width: 767px) {
  .img-container img {
    height: 95%;
  }

  // .Modal {
  //   transform: translate(-50%, 0%) !important;
  //   top: 0px !important;
  //   max-height: 96vh;
  //   overflow: scroll;
  // }

  .form-wrapper p {
    font-size: 16px;
    margin-bottom: 0px;
    border-bottom: none;
  }

  .text-area-heading {
    font-size: 8.8px;
  }

  .echarts-for-react {
    min-width: 270px !important;
  }
  .img-container {
    height: 200px;
    width: 200px;
    margin-top: 0;
    margin-left: 0;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-device-width: 560px) {
  .chart-container.success-popup {
    margin-top: 60px;
    max-height: 269px !important;
  }
}
