.ball {
  animation: bounce 1500ms infinite ease-in-out both;
}

.ball:nth-child(2) {
  animation-delay: 150ms;
}

.ball:nth-child(3) {
  animation-delay: 300ms;
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
