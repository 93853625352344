.progress-wheel-component {
  enable-background: new 0 0 595.3 591;
  width: 100%;
  height: auto;
}

svg.progress-wheel-component path {
  fill: #d0d0d0;
}

svg.progress-wheel-component path.st0 {
  fill: #c24f40;
}

svg.progress-wheel-component path.st1 {
  fill: #c2522f;
}

svg.progress-wheel-component path.st2 {
  fill: #c95b34;
}

svg.progress-wheel-component path.st3 {
  fill: #c96238;
}

svg.progress-wheel-component path.st4 {
  fill: #c96f39;
}

svg.progress-wheel-component path.st5 {
  fill: #c67c38;
}

svg.progress-wheel-component path.st6 {
  fill: #c3823a;
}

svg.progress-wheel-component path.st7 {
  fill: #e9aa44;
}

svg.progress-wheel-component path.st8 {
  fill: #e7b340;
}

svg.progress-wheel-component path.st9 {
  fill: #f2c046;
}

svg.progress-wheel-component path.st10 {
  fill: #f9c555;
}

svg.progress-wheel-component path.st11 {
  fill: #ffcc60;
}

svg.progress-wheel-component path.st12 {
  fill: #ffd27b;
}

svg.progress-wheel-component path.st13 {
  fill: #ffd496;
}

svg.progress-wheel-component path.st14 {
  fill: #ffdf98;
}

svg.progress-wheel-component path.st15 {
  fill: #f8e8b8;
}

svg.progress-wheel-component path.st16 {
  fill: #fdf1c2;
}

svg.progress-wheel-component path.st17 {
  fill: #c6e3c2;
}

svg.progress-wheel-component path.st18 {
  fill: #9cd5c7;
}

svg.progress-wheel-component path.st19 {
  fill: #79cfe1;
}

svg.progress-wheel-component path.st20 {
  fill: #60bdd3;
}

svg.progress-wheel-component path.st21 {
  fill: #4eb0c9;
}

svg.progress-wheel-component path.st22 {
  fill: #4ca2c0;
}

svg.progress-wheel-component path.st23 {
  fill: #468eb5;
}

svg.progress-wheel-component path.st24 {
  fill: #3d79aa;
}

svg.progress-wheel-component path.st25 {
  fill: #4377a8;
}

svg.progress-wheel-component path.st26 {
  fill: #587caf;
}

svg.progress-wheel-component path.st27 {
  fill: #5375ae;
}

svg.progress-wheel-component path.st28 {
  fill: #4b6aac;
}

svg.progress-wheel-component path.st29 {
  fill: #44659f;
}

svg.progress-wheel-component path.st30 {
  fill: #3b6096;
}

svg.progress-wheel-component path.st31 {
  fill: #395686;
}

svg.progress-wheel-component path.st32 {
  fill: #3e4c73;
}

svg.progress-wheel-component path.st33 {
  fill: #394366;
}

svg.progress-wheel-component path.st34 {
  fill: #364261;
}

svg.progress-wheel-component path.st35 {
  fill: #2c3a54;
}

svg.progress-wheel-component path.st36 {
  fill: #26314a;
}

svg.progress-wheel-component path.st37 {
  fill: #a41e22;
}

svg.progress-wheel-component path.st38 {
  fill: #ab2426;
}

svg.progress-wheel-component path.st39 {
  fill: #bb3327;
}

svg.progress-wheel-component path.st40 {
  fill: #b73b29;
}

svg.progress-wheel-component path.st41 {
  fill: #ba4631;
}

svg.progress-wheel-component path.st42 {
  fill: #32b34a;
}

svg.progress-wheel-component path.user {
  fill: #60bdd3;
}

svg.progress-wheel-component path.chip {
  fill: #dd5a57;
}

svg.progress-wheel-component path.other {
  fill: #eee8e2;
}
