.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.33em 0.12em 0 0.12em;
  @apply border-transparent border-t-[#d7ccc0];
}

.outer-circle {
  --percentage: 320 / 360 * 100;
  --border-thickness: 0.33333em;
  --color: #efe8e0;
  position: absolute;
  mask: conic-gradient(from 200deg, var(--color) calc(var(--percentage) * 1%), #0000 0);
  background: radial-gradient(
    farthest-side,
    #0000 calc(100% - var(--border-thickness)),
    var(--color) calc(100% - var(--border-thickness))
  );
}

.colors {
  mask-image: radial-gradient(
    farthest-side,
    #0000 calc(100% - var(--border-thickness)),
    var(--color) calc(100% - var(--border-thickness))
  );
}

.typography-override {
  font-size: 1em !important;
  line-height: 0;
  font-weight: 500;
}

.percentage-amount {
  font-size: 1em !important;
}

.percentage-symbol {
  font-size: 0.33333em !important;
}

.percentage-label {
  font-size: 0.26666em !important;
}
