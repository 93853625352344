.font-suma-label-large {
  font-family: Barlow Condensed, serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.17px;
}

.bg-img-start-goal-saving {
  background-image: url('../assets/images/reelsumaweb10.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 2rem 5px;
  border-color: transparent;
}

.bg-img-dollar-footer {
  background-image: url('../assets/images/footer-dollar.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
