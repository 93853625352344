.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  /* right: 10%;
  bottom: auto; */
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #f7f5f3;
  max-width: 600px;
  max-height: 100vh;
  z-index: 1000;
  overflow: scroll;
}

.Modal:focus {
  outline: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.StripeElement {
  padding: 15px;
  border: none;
  font-family: WorkSans, serif !important;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgb 0 0 0/6%;
  background-color: white;
  width: 90%;
}

.credit-card-error {
  border: 2px solid red !important;
}

@media only screen and (max-device-width: 769px) {
  .StripeElement {
    margin: 0px auto;
    padding: 12px;
  }
}

@media only screen and (max-device-width: 768px) {
  .Modal {
    width: 100%;
    max-height: 92vh;
  }

  .Overlay {
    top: 70px;
  }
}
